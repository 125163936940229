export const NEPAL_SAMBAT = {
  "2024-01-27": "११४४, पोहेलागा, २ शनिबार",
  "2024-01-28": "११४४, पोहेलागा, ३ आइतबार",
  "2024-01-29": "११४४, पोहेलागा, ४ सोमबार",
  "2024-01-30": "११४४, पोहेलागा, ५ मंगलबार",
  "2024-01-31": "११४४, पोहेलागा, ५ बुधबार",
  "2024-02-01": "११४४, पोहेलागा, ६ विहीबार",
  "2024-02-02": "११४४, पोहेलागा, ७ शुक्रबार",
  "2024-02-03": "११४४, पोहेलागा, ८ शनिबार",
  "2024-02-04": "११४४, पोहेलागा, ९ आइतबार",
  "2024-02-05": "११४४, पोहेलागा, १० सोमबार",
  "2024-02-06": "११४४, पोहेलागा, ११ मंगलबार",
  "2024-02-07": "११४४, पोहेलागा, १२ बुधबार",
  "2024-02-08": "११४४, पोहेलागा, १३ विहीबार",
  "2024-02-09": "११४४, पोहेलागा, १४ शुक्रबार",
  "2024-02-10": "११४४, सिल्लाथ्व, १ शनिबार",
  "2024-02-11": "११४४, सिल्लाथ्व, २ आइतबार",
  "2024-02-12": "११४४, सिल्लाथ्व, ३ सोमबार",
  "2024-02-13": "११४४, सिल्लाथ्व, ४ मंगलबार",
  "2024-02-14": "११४४, सिल्लाथ्व, ५ बुधबार",
  "2024-02-15": "११४४, सिल्लाथ्व, ६ विहीबार",
  "2024-02-16": "११४४, सिल्लाथ्व, ७ शुक्रबार",
  "2024-02-17": "११४४, सिल्लाथ्व, ८ शनिबार",
  "2024-02-18": "११४४, सिल्लाथ्व, ९ आइतबार",
  "2024-02-19": "११४४, सिल्लाथ्व, १० सोमबार",
  "2024-02-20": "११४४, सिल्लाथ्व, ११ मंगलबार",
  "2024-02-21": "११४४, सिल्लाथ्व, १२ बुधबार",
  "2024-02-22": "११४४, सिल्लाथ्व, १३ विहीबार",
  "2024-02-23": "११४४, सिल्लाथ्व, १४ शुक्रबार",
  "2024-02-24": "११४४, सिल्लाथ्व, १५ शनिबार",
  "2024-02-25": "११४४, सिल्लागा, १ आइतबार",
  "2024-02-26": "११४४, सिल्लागा, २ सोमबार",
  "2024-02-27": "११४४, सिल्लागा, ३ मंगलबार",
  "2024-02-28": "११४४, सिल्लागा, ४ बुधबार",
  "2024-02-29": "११४४, सिल्लागा, ५ विहीबार",
  "2024-03-01": "११४४, सिल्लागा, ६ शुक्रबार",
  "2024-03-02": "११४४, सिल्लागा, ७ शनिबार",
  "2024-03-03": "११४४, सिल्लागा, ८ आइतबार",
  "2024-03-04": "११४४, सिल्लागा, ९ सोमबार",
  "2024-03-05": "११४४, सिल्लागा, १० मंगलबार",
  "2024-03-06": "११४४, सिल्लागा, ११ बुधबार",
  "2024-03-07": "११४४, सिल्लागा, १२ विहीबार",
  "2024-03-08": "११४४, सिल्लागा, १३ शुक्रबार",
  "2024-03-09": "११४४, सिल्लागा, १४ शनिबार",
  "2024-03-10": "११४४, सिल्लागा, ३० आइतबार",
  "2024-03-11": "११४४, चिल्लाथ्व, १ सोमबार",
  "2024-03-12": "११४४, चिल्लाथ्व, २ मंगलबार",
  "2024-03-13": "११४४, चिल्लाथ्व, ३ बुधबार",
  "2024-03-14": "११४४, चिल्लाथ्व, ४ विहीबार",
  "2024-03-15": "११४४, चिल्लाथ्व, ६ शुक्रबार",
  "2024-03-16": "११४४, चिल्लाथ्व, ७ शनिबार",
  "2024-03-17": "११४४, चिल्लाथ्व, ८ आइतबार",
  "2024-03-18": "११४४, चिल्लाथ्व, ९ सोमबार",
  "2024-03-19": "११४४, चिल्लाथ्व, १० मंगलबार",
  "2024-03-20": "११४४, चिल्लाथ्व, ११ बुधबार",
  "2024-03-21": "११४४, चिल्लाथ्व, १२ विहीबार",
  "2024-03-22": "११४४, चिल्लाथ्व, १३ शुक्रबार",
  "2024-03-23": "११४४, चिल्लाथ्व, १३ शनिबार",
  "2024-03-24": "११४४, चिल्लाथ्व, १४ आइतबार",
  "2024-03-25": "११४४, चिल्लाथ्व, १५ सोमबार",
  "2024-03-26": "११४४, चिल्लागा, १ मंगलबार",
  "2024-03-27": "११४४, चिल्लागा, २ बुधबार",
  "2024-03-28": "११४४, चिल्लागा, ३ विहीबार",
  "2024-03-29": "११४४, चिल्लागा, ४ शुक्रबार",
  "2024-03-30": "११४४, चिल्लागा, ५ शनिबार",
  "2024-03-31": "११४४, चिल्लागा, ६ आइतबार",
  "2024-04-01": "११४४, चिल्लागा, ७ सोमबार",
  "2024-04-02": "११४४, चिल्लागा, ८ मंगलबार",
  "2024-04-03": "११४४, चिल्लागा, ९ बुधबार",
  "2024-04-04": "११४४, चिल्लागा, १० विहीबार",
  "2024-04-05": "११४४, चिल्लागा, ११ शुक्रबार",
  "2024-04-06": "११४४, चिल्लागा, १२ शनिबार",
  "2024-04-07": "११४४, चिल्लागा, १४ आइतबार",
  "2024-04-08": "११४४, चिल्लागा, ३० सोमबार",
  "2024-04-09": "११४४, चौलाथ्व, १ मंगलबार",
  "2024-04-10": "११४४, चौलाथ्व, २ बुधबार",
  "2024-04-11": "११४४, चौलाथ्व, ३ विहीबार",
  "2024-04-12": "११४४, चौलाथ्व, ४ शुक्रबार"
}