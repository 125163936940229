import { useQuery, useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { Axios } from '../../apis/configs/axiosConfig';
import { authHeader } from '../../_helpers/auth-header';

interface IQueryOptions {
	onSuccess?: (data: any) => void;
	onError?: (error: any) => void;
	refetchInterval?: number;
	enabled?: boolean;
}

type paramType = [string, string | number | boolean] | string[];
interface IQueryHook {
	queryName: string;
	queryRoute: string;
	params?: paramType[];
	options?: IQueryOptions;
	env?: string;
}

export const useQueryHook = ({ queryName, queryRoute, params = [], options = {}, env }: IQueryHook) => {
	const requestService = async () => {
		try {
			let queryParams = params.map((p) => p.join('='));
			let response = await Axios({ env: env }).get(queryRoute + (params.length > 0 ? `?${queryParams.join('&')}` : ''));
			return response?.data || {};
		} catch (error) {
			console.log(error);
		}
	};
	return useQuery({
		queryKey: [queryName, ...params],
		queryFn: requestService,
		...options,
	});
};

interface IMutationHook {
	queryRoute: string;
	method?: 'post' | 'patch' | 'put' | 'delete';
	params?: [string, string | number][];
	options?: IQueryOptions;
	axiosOptions?: any;
	env?: string | null;
}

export const useMutationHook = ({
	queryRoute,
	method = 'post',
	params = [],
	options = {},
	axiosOptions = {},
	env = null,
}: IMutationHook) => {
	let queryParams = params.map((p) => p.join('='));
	return useMutation({
		mutationFn: async (data: { [key: string]: any }) => {
			let response = await axios({
				method: method,
				url: `${process.env[env ? env : 'REACT_APP_BACKEND_API']}${queryRoute + (params.length > 0 ? `?${queryParams.join('&')}` : '')
					}`,
				headers: {
					...authHeader(axiosOptions.multipart),
				},
				data: data,
			});
			return response.data.data || response;
		},
		...options,
	});
};
