import { combineReducers } from '@reduxjs/toolkit';
import languageReducer from './languages';
import toastReducer from './toast';
import authReducer from './auth';

const rootReducers = combineReducers({
	toast: toastReducer,
	auth: authReducer,
	language: languageReducer,
});

export default rootReducers;
