import Text from '../@InputElements/Text';
import RadioComponent from '../@InputElements/Radio/Radio';
import SelectComponent from '../@InputElements/Select';
import DatePicker from '../DatePicker';
import classNames from 'classnames';

/**
 *
 */
export default function FormInput({
	label,
	id,
	type,
	className,
	placeholder,
	defaultValue,
	isDisabled,
	options,
	getInputData,
	subType,
	noMarginBottom,
	onEnterPress,
	isMulti,
	error,
	innerRef
}: IFormInputProps) {
	let element;
	switch (type) {
		case 'Text':
			element = (
				<Text
					innerRef={innerRef}
					// register={register}
					elementId={id}
					// errors={errors}
					// language={language}
					className={classNames(`input focus:outline-none focus:border-blue-500 focus:border-2 input-bordered w-full`, className, error ? 'border-red-500 border-2' : '')}
					placeholder={placeholder}
					value={defaultValue}
					// setValue={setValue}
					subType={subType}
					// validations={formValidations}
					disabled={isDisabled}
					getInputData={getInputData}
					onEnterPress={onEnterPress}
				/>
			);
			break;
		case 'Select':
			element = (
				<SelectComponent
					elementId={id}
					selectValue={defaultValue}
					options={options ?? []}
					handleSelectChange={getInputData}
					isMulti={isMulti}
					isDisabled={isDisabled}
				/>
			);
			break;

		case 'Radio':
			element = (
				<RadioComponent
					elementId={id}
					selectedValue={defaultValue}
					options={options ?? []}
					handleRadioChange={getInputData}
				/>
			);
			break;
		case 'Date':
			element = <DatePicker elementId={id} defaultValue={defaultValue} handleDateChange={getInputData} isDisabled={isDisabled} />;
			break;
		default:
			element = <input className="w-full bg-gray-50 border rounded px-3 py-2 focus:outline-blue-400" />;
	}

	return (
		<div className={noMarginBottom ? '' : 'mb-4'}>
			{label && <div className="text-gray-700 mb-1 font-bold">{label}</div>}
			{element}
			{error && <div className='text-red-500 text-sm'>{error}</div>}
		</div>
	);
}

interface IFormInputProps {
	label?: string;
	id: string;
	type: 'Text' | 'Select' | 'Radio' | 'Date';
	subType?: 'number' | 'textarea' | 'text';
	className?: string;
	placeholder?: string;
	defaultValue?: any;
	innerRef?: any;
	isDisabled?: boolean;
	getInputData?: (value: string) => void;
	options?: any;
	noMarginBottom?: boolean;
	onEnterPress?: (value: string) => void;
	isMulti?: boolean;
	error?: string;
}
