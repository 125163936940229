import { FC } from 'react';
import { Helmet } from "react-helmet";
import { Routes, Route } from 'react-router-dom';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

//
import Toast from './components/Toast';
import AuthLayout from './layout/auth';

//
import DashLayout from './layout/DashLayout';
import ProtectedLayout from './layout/protected';

//
import Mail from './Pages/Mail';
import Inbox from './Pages/Inbox';
import Compose from './Pages/Compose';
import LoginPage from './Pages/Login';
import Sent from './Pages/Sent';
import Draft from './Pages/Draft';
import Templates from './Pages/Templates/Templates';
import Template from './Pages/Template/Template';
import SearchPage from './Pages/Search';
import PreviewPage from './Pages/Preview';
import StarredPage from './Pages/Starred';
import Settings from './Pages/Settings';
import FiscalYear from './Pages/FiscalYear';
import WardOffices from './Pages/WardOffices';
import WardForm from './Pages/WardForm';
import WardOffice from './Pages/WardOffice';
import ITOUsers from './Pages/ITOUsers';
import NagarpalikaForm from './Pages/nagarpalika';
import { useAppSelector } from './hooks/redux';
import ProtectedRoute from './components/app/ProtectedRoute';


/**
 *
 */
const App: FC = () => {
	const nagarpalikaInfo = useAppSelector(state => state?.auth?.user?.nagarpalika_info)
	return (
		<>
			<Helmet>
				<title>
					{
						`${nagarpalikaInfo?.nagarpalika_fullname_np ? `${nagarpalikaInfo?.nagarpalika_fullname_np} | ` : ''} पत्राचार प्रणाली`
					}
				</title>
			</Helmet>

			<Routes>
				<Route element={<ProtectedLayout />}>
					<Route path='/login' element={<LoginPage />} />
				</Route>

				<Route element={<AuthLayout />}>
					<Route path="/" element={<DashLayout />}>
						{/* <Route index element={<Dash />} /> */}
						<Route index element={<Inbox />} />
						<Route path="/starred" element={<StarredPage />} />
						<Route path="search" element={<SearchPage />} />
						<Route path="sent" element={<Sent />} />
						<Route path="draft" element={<Draft />} />
						<Route path="/inbox/:id" element={<Mail isInbox={true} />} />
						<Route path="/sent-mail/:id" element={<Mail />} />
						<Route path="compose" element={<Compose />} />
						<Route path="templates" element={<Templates />} />
						<Route path="template/:templateId" element={<Template />} />
						<Route path="preview/:paperId" element={<PreviewPage />} />
						<Route path="settings" element={<ProtectedRoute allowedRoles={['ito_admin']} />}>
							<Route index element={<Settings />} />
							<Route path="fiscal-year" element={<FiscalYear />} />

							<Route path="ward-offices" element={<WardOffices />} />
							<Route path="ward-offices/new" element={<WardForm />} />
							<Route path="ward-offices/edit/:id" element={<WardForm />} />
							<Route path="ward-office/:wardId" element={<WardOffice />} />

							<Route path="ito-users" element={<ITOUsers />} />
							<Route path="nagarpalika" element={<NagarpalikaForm />} />

						</Route>

					</Route>
				</Route>
			</Routes>
			<Toast />
			<ReactQueryDevtools initialIsOpen={false} />
		</>
	);
};

export default App;
